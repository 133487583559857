<template>
  <div class="monitor-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-date-picker
                v-model="listfilter.fstarttime"
                type="date"
                size="medium"
                placeholder="开始日期"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
                :clearable="true"
                style="width: 180px;margin-left:10px;"
                @change="onSearch"
            >
            </el-date-picker>
            -
            <el-date-picker
                v-model="listfilter.fendtime"
                type="date"
                size="medium"
                placeholder="结束日期"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
                :clearable="true"
                style="width: 180px;"
                @change="onSearch"
            >
            </el-date-picker>

            <el-button
                style="margin-left: 10px"
                type="primary"
                size="medium"
                icon="el-icon-search"
                @click="onSearch"
                >查询</el-button
            >
          </el-form>
        </div>
        <div class="action-right">
          <el-button type="primary" size="medium" @click="onImportData"
            >导出数据</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      class="list-table"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
    >
      <el-table-column label="设备编号" prop="DeviceId"></el-table-column>
      <el-table-column label="传感器" prop="SensorId"></el-table-column>
      <el-table-column prop="V1" :key="Math.random()">
        <template slot="header"> {{ getName("v1") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V1 | handleData }}
        </template>
      </el-table-column>
      <el-table-column prop="V2" :key="Math.random()">
        <template slot="header"> {{ getName("v2") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V2 | handleData }}
        </template>
      </el-table-column>
      <el-table-column prop="V3" :key="Math.random()">
        <template slot="header"> {{ getName("v3") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V3 | handleData }}
        </template>
      </el-table-column>
      <el-table-column prop="V4" :key="Math.random()">
        <template slot="header"> {{ getName("v4") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V4 | handleData }}
        </template>
      </el-table-column>
      <el-table-column prop="V5" :key="Math.random()">
        <template slot="header"> {{ getName("v5") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V5 | handleData }}
        </template>
      </el-table-column>
      <el-table-column prop="V6" :key="Math.random()">
        <template slot="header"> {{ getName("v6") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V6 | handleData }}
        </template>
      </el-table-column>
      <el-table-column prop="V7" :key="Math.random()">
        <template slot="header"> {{ getName("v7") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V7 | handleData }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="V8" :key="Math.random()">
        <template slot="header"> {{ getName("v8") }} </template>
        <template slot-scope="scope">
          {{ scope.row.V8 | handleData }}
        </template>
      </el-table-column> -->
      <el-table-column label="时间" prop="DeviceDataTime" width="160">
        <template slot-scope="scope">
          {{ scope.row.DeviceDataTime | datetime }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getDeviceDataList, importDeviceData } from "@/api/device";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      filterDaterange: "",

      listfilter: {
        deviceid: "",
        fstarttime: "",
        fendtime: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },

      namelist: [],
    };
  },
  props: {
    detailid: [String, Number],
  },
  computed: {},
  components: {},
  methods: {
    getName(value) {
      var keyname = value;
      var namelist = this.namelist;
      for (let index = 0; index < namelist.length; index++) {
        const element = namelist[index];
        if (element.field[0] == value) {
          keyname = value + "(" + element.name[0] + ")";
          break;
        }
      }
      keyname = keyname + "";
      return keyname;
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      this.listfilter.deviceid = this.detailid;
      getDeviceDataList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.namelist = resdata.data.namelist;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onImportData() {
      this.listfilter.deviceid = this.detailid;
      const loading = this.$loading({
        lock: true,
        text: "导出中，请稍等...",
        background: "rgba(55, 55, 55, 0.4)",
      });
      importDeviceData(this.listfilter)
        .then(() => {
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
  },
  created() {
    if (this.detailid) {
      this.getListData();
    }
  },
  filters: {
    handleData: function (value) {
      if (value || value == 0) {
        return value;
      }
      return "-";
    },
  },
  watch: {
    detailid: function () {
      if (this.detailid) {
        this.getListData();
      }
    },
  },
};
</script>