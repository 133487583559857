<template>
  <div v-loading="pageload" style="position: relative">
    <div class="page-device-detail-goback" @click="onGoback">
      <i class="el-icon-back"></i>返回
    </div>
    <el-tabs class="geology-tabs" v-model="activeTab">
      <el-tab-pane label="监测曲线" name="tabCurve" :lazy="true">
        <el-row :gutter="20" class="device-curve-info">
          <el-col :span="6">
            <div class="container-box" style="border-radius: 6px">
              <el-row style="padding: 0 10px">
                <el-col :span="24" class="device-list-item">监测点：{{ detailData.DangerName }}
                </el-col>
                <el-col :span="24" class="device-list-item">监测点位：{{ detailData.MonitorName }}
                </el-col>
                <el-col :span="24" class="device-list-item">设备名称：{{ detailData.DeviceName }}
                </el-col>
                <el-col :span="24" class="device-list-item">设备类型：{{ detailData.DeviceType }}
                </el-col>
                <el-col :span="24" class="device-list-item">设备编号：{{ detailData.DeviceCode }}
                </el-col>
                <el-col :span="24" class="device-list-item">设备状态：{{
                    detailData.IsOn == true
                      ? "在线"
                      : "离线"
                }}
                </el-col>
                <el-col :span="24" class="device-list-item">监测地址：{{ detailData.Address }}
                </el-col>
                <el-col :span="24" class="device-list-item">预警功能：
                  <el-switch v-model="detailData.IsWaring" @change="onDeviceWarningChange"></el-switch>
                </el-col>
                <el-col :span="24" class="device-list-item">语音通知：
                  <el-switch v-model="detailData.VoiceNotice" :active-value="1" :inactive-value="0"
                    @change="onDeviceVoiceChange"></el-switch>
                </el-col>
                <!-- <el-col :span="24" class="device-list-item"
                                    >最新数据：{{ detailData.DeviceType }}
                                </el-col> -->
              </el-row>
            </div>
          </el-col>
          <el-col :span="18">
            <div v-if="detailData.DeviceType != '视频设备'" class="geology-detail-content-white">
              <div class="geology-detail-content-title">
                监测曲线
              </div>
              <el-form class="geology-form" style="margin-bottom: 10px" :inline="true">
                <div class="date-secion">
                  <el-form-item label="">
                    <el-date-picker v-model="lineDataFilter.fstarttime" type="date" size="medium" placeholder="开始日期"
                      format="yyyy年MM月dd日" value-format="yyyy-MM-dd" @change="getDeviceLineData" :clearable="false"
                      style="width: 180px;">
                    </el-date-picker>
                    -
                    <el-date-picker v-model="lineDataFilter.fendtime" type="date" size="medium" placeholder="结束日期"
                      format="yyyy年MM月dd日" value-format="yyyy-MM-dd" @change="getDeviceLineData" :clearable="false"
                      style="width: 180px;">
                    </el-date-picker>
                    <!-- <el-date-picker
                                            v-model="filterDaterange"
                                            type="daterange"
                                            range-separator="-"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            size="medium"
                                            style="
                                                width: 240px;
                                                margin-left: 10px;
                                            "
                                            value-format="timestamp"
                                            :clearable="false"
                                            @change="dateChange"
                                        >
                                        </el-date-picker> -->
                  </el-form-item>
                  <el-form-item :label="
                    detailData.DeviceType == '雨量计'
                      ? '年平均降雨量：'
                      : '平均变形速率：'
                  " style="margin-left: 10px" v-if="
  userinfo.isAdmin &&
  showDeviceAvgrate &&
  deviceAvgrateState == 'show'
">
                    <span>{{ deviceAvgrate }}</span>
                    <span>{{
                        detailData.DeviceType == "雨量计"
                          ? "mm"
                          : "mm/d"
                    }}</span>
                    <el-button type="primary" @click="deviceAvgrateState = 'edit'" style="margin-left: 10px"
                      size="mini">修改</el-button>
                  </el-form-item>
                  <el-form-item :label="
                    detailData.DeviceType == '雨量计'
                      ? '年平均降雨量：'
                      : '平均变形速率：'
                  " style="margin-left: 10px" v-if="deviceAvgrateState == 'edit'">
                    <el-input v-model="deviceAvgrateEditValue" placeholder="" style="width: 120px" size="medium">
                    </el-input>
                    <el-button type="primary" @click="onDeviceAvgrateSubmit" style="margin-left: 10px" size="medium">
                      保存
                    </el-button>
                    <el-button @click="deviceAvgrateState = 'show'" style="margin-left: 10px" size="medium">取消
                    </el-button>
                  </el-form-item>
                </div>
              </el-form>

              <el-tabs class="geology-tabs" v-model="activeCharTab" style="padding: 0 10px"
                v-if="deviceSensorData.length > 1" @tab-click="onTabChange">
                <el-tab-pane :label="item.name" :name="'sensor' + index" v-for="(item, index) in deviceSensorData"
                  :key="index" :lazy="true">
                  <div v-if="
                    item.chartGnnsData.xAxis &&
                    item.chartGnnsData.xAxis.length > 0
                  ">
                    <!-- 雨量计 -->
                    <template v-if="charLineMin == 0.5">
                      <chart-bar :id="'chartBarData' + index" class="device-chart" :yname="item.unit"
                        :min-interval="charLineMin" :data="item.chartGnnsData"></chart-bar>
                      <chart-ylj-warning style="margin-top: 50px" :id="
                        'chartYljWarningData' +
                        index
                      " :data="yljDeviceWaringData" :annualrainfall="deviceAvgrate" v-if="deviceAvgrate > 0">
                      </chart-ylj-warning>
                    </template>
                    <!-- 测斜仪 -->
                    <chart-tilt :id="'chartTiltData' + index" class="device-chart device-chart-tilt" :yname="item.unit"
                      :min-interval="charLineMin" :data="item.chartGnnsData" v-else-if="charLineMin == 2"></chart-tilt>
                    <!-- 其他 -->
                    <template v-else>
                      <chart-line :id="'chartLineData' + index" class="device-chart" :device-type="
                        detailData.DeviceType
                      " :yname="item.unit" :min-interval="charLineMin" :data="item.chartGnnsData"></chart-line>

                      <chart-line-tangent :id="'chartLineTangentData0' + index" class="device-chart" yname="预警切线图"
                        style="margin-top: 50px" v-if="
                          (detailData.DeviceType == 'GNSS' || detailData.DeviceType == '裂缝计') && deviceSensorData[0]
                            .chartAngleData.series
                            .length > 0 &&
                          (item.chartAngleData.series[0]
                            .data.length > 0 ||
                            item.chartAngleData
                              .series[1].data.length >
                            0)
                        " :data="item.chartAngleData"></chart-line-tangent>


                      <chart-zxrate :id="'chartZxrateData0' + index" class="device-chart"
                        v-if="showDeviceZxrate && item.chartZxrate && item.chartZxrate.data.length > 0"
                        :chartdata="item.chartZxrate"></chart-zxrate>
                    </template>
                  </div>

                  <div v-else class="device-chart" style="text-align: center" v-loading="
                    lineWarpText != '暂无数据'
                      ? true
                      : false
                  ">
                    {{ lineWarpText }}
                  </div>
                </el-tab-pane>
              </el-tabs>

              <div v-else>
                <div v-if="deviceSensorData.length > 0">
                  <!-- 雨量计 -->
                  <template v-if="charLineMin == 0.5">
                    <chart-bar :id="'chartBarData0'" class="device-chart" :yname="deviceSensorData[0].unit"
                      :min-interval="charLineMin" :data="
                        deviceSensorData[0]
                          .chartGnnsData
                      "></chart-bar>
                    <chart-ylj-warning style="
                                                height: 300px;
                                                margin-top: 50px;
                                            " :id="'chartYljWarningData0'" :data="yljDeviceWaringData"
                      :annualrainfall="deviceAvgrate" v-if="deviceAvgrate > 0"></chart-ylj-warning>
                  </template>
                  <!-- 测斜仪 -->
                  <chart-tilt :id="'chartTiltData0'" class="device-chart-tilt" :min-interval="charLineMin" :data="
                    deviceSensorData[0].chartGnnsData
                  " v-else-if="charLineMin == 2"></chart-tilt>
                  <!-- 其他 -->
                  <template v-else>
                    <chart-line :id="'chartLineData0'" class="device-chart" :device-type="detailData.DeviceType"
                      :yname="deviceSensorData[0].unit" :min-interval="charLineMin" :data="
                        deviceSensorData[0]
                          .chartGnnsData
                      "></chart-line>

                    <chart-line-tangent :id="'chartLineData01'" class="device-chart" yname="预警切线图"
                      style="margin-top: 50px" v-if="
                        (detailData.DeviceType == 'GNSS' || detailData.DeviceType == '裂缝计') && deviceSensorData[0]
                          .chartAngleData.series
                          .length > 0
                      " :data="
  deviceSensorData[0]
    .chartAngleData
"></chart-line-tangent>

                    <chart-zxrate :id="'chartZxrateData01'" class="device-chart"
                      v-if="showDeviceZxrate && deviceSensorData[0].chartZxrate && deviceSensorData[0].chartZxrate.data && deviceSensorData[0].chartZxrate.data.length > 0"
                      :chartdata="deviceSensorData[0].chartZxrate"></chart-zxrate>

                  </template>
                </div>
                <div v-else class="device-chart" style="text-align: center" v-loading="
                  lineWarpText != '暂无数据'
                    ? true
                    : false
                ">
                  {{ lineWarpText }}
                </div>
              </div>
            </div>
            <div v-else class="geology-detail-content-white">
              <div class="geology-detail-content-title">实时视频监控</div>
              <div style="height:350px;width:100%;">
                <camera-video id="device-video" :url="detailData.VideoUrl"></camera-video>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="设备信息" name="tabDevice">
        <el-form ref="form" class="geology-form" label-width="120px">
          <el-divider content-position="left">基本信息</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备名称:">
                {{ detailData.DeviceName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备类型:">
                {{ detailData.DeviceType }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备编号:">
                {{ detailData.DeviceCode }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备IEMI:">
                {{ detailData.DeviceIemi }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点:">
                {{ detailData.DangerName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点位:">
                {{ detailData.MonitorName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点编号:">
                {{ detailData.MonitorCode }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测地址:">
                {{ detailData.Address }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="经度:">
                {{ detailData.Longitude }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="纬度:">
                {{ detailData.Latitude }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="固件版本:">
                {{ detailData.SoftVer }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="物联网卡:">
                {{ detailData.MobileCard }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="安装时间:">
                {{ detailData.InstallTime | datetime }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="更新时间:">
                {{ detailData.LastUpdateTime | datetime }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="在线状态:">
                {{ detailData.IsOn == true ? "在线" : "离线" }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="预警信息接收人" name="tabWarnpush">
        <warn-push :deviceInfo="detailData"></warn-push>
      </el-tab-pane>
      <el-tab-pane label="预警信息" name="tabWarning">
        <detail-warning-info :deviceid="detailid"></detail-warning-info>
      </el-tab-pane>
      <el-tab-pane label="运行数据" name="tabRundata">
        <detail-rundata :detailid="detailid"></detail-rundata>
      </el-tab-pane>
      <!-- <el-tab-pane label="设备状态" name="tabDevicestate"> </el-tab-pane> -->
      <el-tab-pane label="命令下发" name="tabCommand">
        <detail-command :deviceid="detailid"></detail-command>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style lang="scss">
.geology-detail-content-white {
  background: #fff;
  border-radius: 6px;
  padding: 10px 20px;

  .geology-form .el-input__inner,
  .geology-form .el-textarea__inner {
    background: #0452b2;
    border: none;
    box-shadow: none;
  }

  .geology-form {
    color: #0452b2;

    .el-form-item__label {
      color: #0452b2;
    }
  }
}

.geology-detail-content-title {
  font-size: 16px;
  color: #0452b2;
  position: relative;
  padding: 10px 10px 10px 20px;

  &:before {
    content: " ";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 40%;
    width: 4px;
    background: #0452b2;
    border-radius: 4px;
  }
}

.device-chart {
  width: 100%;
  height: 260px;
  line-height: 60px;
  color: #0452b2;

  .el-loading-mask {
    background: transparent;
  }
}

.device-chart-tilt {
  width: 100%;
  height: 620px;
  line-height: 60px;
}
</style>
<style lang="scss" scoped>
.device-curve-info {
  color: #20fefd;
}

.device-list-item {
  padding: 10px 0;
  line-height: 1.4;
}

@media (min-width: 1400px) {
  .device-chart {
    height: 360px;
    line-height: 100px;
  }

  .device-chart-tilt {
    width: 100%;
    height: 1040px;
    line-height: 60px;
  }
}

.date-secion {
  vertical-align: middle;

  .el-button-group {
    .el-button:first-child {
      border-radius: 0;
    }
  }

  .el-date-editor {
    width: 200px;
    vertical-align: middle;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import {
  getDeviceDetail,
  getDeviceLineData,
  getWaringConfig,
  setWaringConfig,
  getYljDeviceWaringData,
  editDeviceWaringStatus,
  editDeviceVoiceNotice,
  UpdateVideoPlay,
} from "@/api/device";
import chartLine from "./components/chart-line";
import chartLineTangent from "./components/chart-line-tangent";
import chartBar from "./components/chart-bar";
import chartTilt from "./components/chart-tilt";
import chartYljWarning from "./components/chart-ylj-warning";
import chartZxrate from "./components/chart-zxrate.vue";

import detailRundata from "./components/detailRundata";
import warnPush from "./components/warnPush";
import detailCommand from "./command";
import detailWarningInfo from "../warning/index";
var echarts = require("echarts/lib/echarts");

import CameraVideo from "@/components/cameravideo";

export default {
  data() {
    return {
      pageload: false,
      activeTab: "tabCurve",
      detailData: "",
      clientHeight: 0,

      deviceAvgrateState: "show",
      deviceAvgrate: "",
      deviceAvgrateEditValue: "",

      filterDaterange: "",
      activedate: "",
      lineDataFilter: {
        deviceid: "",
        fstarttime: "",
        fendtime: "",
      },
      lineWarpText: "数据加载中...",
      activeCharTab: "sensor0",
      deviceSensorData: [],
      chartGnnsData: {
        xAxis: [],
        series: [],
      },

      yljDeviceWaringData: [], //雨量计预警数据

      lineInterval: '',
    };
  },
  props: {
    detailid: [String, Number],
  },
  computed: {
    ...mapGetters(["userinfo"]),
    charLineMin() {
      if (this.detailData.DeviceType == "裂缝计") {
        return 10;
      } else if (this.detailData.DeviceType == "雨量计") {
        return 0.5;
      } else if (this.detailData.DeviceType == "测斜仪") {
        return 2;
      } else if (this.detailData.DeviceType == "GNSS") {
        return 10;
      } else {
        return 1;
      }
    },
    showDeviceAvgrate() {
      if (
        this.detailData.DeviceType == "裂缝计" ||
        this.detailData.DeviceType == "GNSS" ||
        this.detailData.DeviceType == "雨量计"
      ) {
        return true;
      } else {
        return false;
      }
    },
    showDeviceZxrate() {
      if (
        this.detailData.DeviceType == "裂缝计" ||
        this.detailData.DeviceType == "GNSS"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    chartLine,
    chartLineTangent,
    chartYljWarning,
    chartZxrate,
    chartBar,
    chartTilt,
    detailRundata,
    detailCommand,
    detailWarningInfo,
    warnPush,
    CameraVideo,
  },
  methods: {
    dateChange(value) {
      if (value) {
        var newdate = [];
        for (
          let index = 0;
          index < this.filterDaterange.length;
          index++
        ) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }

        var getDateStr = function (datetime) {
          var dd = new Date(datetime);
          var y = dd.getFullYear();
          var m =
            dd.getMonth() + 1 < 10
              ? "0" + (dd.getMonth() + 1)
              : dd.getMonth() + 1;
          var d =
            dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
          var datestr = y + "-" + m + "-" + d;
          return datestr;
        };

        this.lineDataFilter.fstarttime = getDateStr(newdate[0]);
        this.lineDataFilter.fendtime = getDateStr(newdate[1]);
      } else {
        this.lineDataFilter.fstarttime = "";
        this.lineDataFilter.fendtime = "";
      }
      this.getDeviceLineData();
    },
    onDateClick(day) {
      var getDateStr = function (AddDayCount, time) {
        var dd = new Date();
        dd.setDate(dd.getDate() + AddDayCount);
        var y = dd.getYear() + 1900;
        var m =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        var datestr = y + "-" + m + "-" + d + " " + time;
        return new Date(datestr).getTime();
      };
      this.filterDaterange = [
        getDateStr(day * -1 + 1, "0:0:0"),
        getDateStr(0, "23:59:59"),
      ];
      this.dateChange(this.filterDaterange);
    },
    getDeviceDetail() {
      const promiseDetail = new Promise((resolve, reject) => {
        getDeviceDetail(this.detailid)
          .then((res) => {
            if (res.code == 0) {
              this.detailData = res.data[0];

              if (this.detailData && this.detailData.DeviceType != '视频设备') {
                this.lineInterval = setInterval(() => {
                  this.getDeviceLineData();
                }, 10 * 1000);
              }
              resolve();
            } else {
              this.$message.error(res.msg);
              reject();
            }
          })
          .catch((errmsg) => {
            this.$message.error(errmsg);
            reject();
          });
      });
      const promiseWaring = new Promise((resolve, reject) => {
        getWaringConfig(this.detailid)
          .then((res) => {
            if (res.code == 0) {
              this.deviceAvgrate = res.data;
              this.deviceAvgrateEditValue = res.data * 1;
              resolve();
            } else {
              reject();
            }
          })
          .catch((errmsg) => {
            this.$message.error(errmsg);
            reject();
          });
      });
      this.pageload = true;
      Promise.all([promiseDetail, promiseWaring]).then(() => {
        this.pageload = false;
        this.onDateClick(7);
      });
    },
    getDeviceLineData() {
      const that = this;
      this.lineDataFilter.deviceid = this.detailid;
      console.log(this.lineDataFilter);
      getDeviceLineData(this.lineDataFilter)
        .then((res) => {
          if (res.code == 0) {
            if (res.data.length == 0) {
              this.lineWarpText = "暂无数据";
            }
            var deviceSensorData = [];
            if (that.detailData.DeviceType == "测斜仪") {
              this.deviceSensorData = [
                {
                  chartGnnsData: {
                    series: res.data,
                  },
                },
              ];
            } else {
              for (
                let index = 0;
                index < res.data.length;
                index++
              ) {
                const element = res.data[index];
                var xAxis = element.x;
                var xAxisAngle = '';
                var series = [];
                var angleseries = [];
                var zxrateseries = '';
                for (let j = 0; j < element.list.length; j++) {
                  const item = element.list[j];
                  series.push({
                    name: item.name + '(' + item.unit + ')',
                    type: "bar",
                    data: item.y,
                    rawdata: item,
                  });
                  if (
                    that.detailData.DeviceType == "雨量计"
                  ) {
                    series.push({
                      name: "累计雨量(mm)",
                      type: "line",
                      data: item.rainfalls,
                    });
                  }

                  xAxisAngle = item.ratex;
                  angleseries.push(
                    {
                      name: "变形速率",
                      data: item.rate,
                    },
                    {
                      name: "切线角",
                      data: item.angle,
                    }
                  );

                  if (item.zxrate.length > 0) {
                    var mintime = item.mintime.split(',').length > 1 ? item.mintime.split(',')[1] : '';
                    var maxtime = item.maxtime.split(',').length > 1 ? item.maxtime.split(',')[1] : '';
                    zxrateseries = {
                      name: '速度倒数',
                      xaxis: item.zxtime,
                      data: item.zxrate,
                      mintime: mintime,
                      maxtime: maxtime,
                    };
                  }
                }

                let deviceSensorName = '传感器' + element.sensor;
                if (element.list.length > 0) {
                  deviceSensorName = element.list[0].showname ? element.list[0].showname : element.list[0].name;
                }
                deviceSensorData.push({
                  sensor: element.sensor,
                  name: deviceSensorName,
                  unit: deviceSensorName + "(" + element.list[0].unit + ")",
                  chartGnnsData: {
                    xAxis: xAxis,
                    series: series,
                    list: element.list,
                  },
                  chartAngleData: {
                    xAxis: xAxisAngle,
                    series: angleseries,
                    list: element.list,
                  },
                  chartZxrate: zxrateseries
                });
              }
              this.deviceSensorData = deviceSensorData;
            }

          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((errmsg) => {
          this.$message.error(errmsg);
        });

      if (that.detailData.DeviceType == "雨量计") {
        getYljDeviceWaringData(this.lineDataFilter)
          .then((res) => {
            if (res.code == 0) {
              this.yljDeviceWaringData = res.data;
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((errmsg) => {
            this.$message.error(errmsg);
          });
      }
    },
    drawLineData() { },
    onDeviceAvgrateSubmit() {
      if (this.deviceAvgrateEditValue) {
        if (this.deviceAvgrateEditValue * 1 == this.deviceAvgrate) {
          return;
        }
        setWaringConfig(this.detailid, this.deviceAvgrateEditValue)
          .then((res) => {
            if (res.code == 0) {
              this.deviceAvgrate =
                this.deviceAvgrateEditValue * 1;
              this.deviceAvgrateState = "show";
              this.$message.success("保存成功!");
              this.getDeviceLineData();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((errmsg) => {
            this.$message.error(errmsg);
          });
      }
    },
    onDeviceWarningChange() {
      editDeviceWaringStatus(this.detailid, this.detailData.IsWaring)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("保存成功!");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((errmsg) => {
          this.$message.error(errmsg);
        });
    },
    onDeviceVoiceChange() {
      //语音通知变更
      editDeviceVoiceNotice(this.detailid, this.detailData.VoiceNotice)
        .then(res => {
          if (res.code == 0) {
            this.$message.success("保存成功!");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((errmsg) => {
          this.$message.error(errmsg);
        });
    },
    onGoback() {
      this.$router.go(-1);
    },
    onTabChange() {
      var nowDom = document.getElementById('pane-' + this.activeCharTab);
      if (nowDom) {
        var charts = nowDom.querySelectorAll('.device-chart');
        if (charts && charts.length > 0) {
          for (let index = 0; index < charts.length; index++) {
            const element = charts[index];
            var compareChart = echarts.getInstanceByDom(document.getElementById(element.id));
            if (compareChart) {
              (function (compareChart, element) {
                setTimeout(() => {
                  compareChart.resize({
                    width: element.clientWidth
                  });
                }, 50);
              })(compareChart, element)

            }
          }
        }
      }
    },

    refreshVideoData() {
      UpdateVideoPlay(this.detailid);

    }
  },
  mounted() {
    var that = this;
    window.onresize = function () {
      var clientHeight = document.body.clientHeight - 190;
      that.clientHeight = clientHeight;
    };
    var clientHeight = document.body.clientHeight - 190;
    that.clientHeight = clientHeight;

    if (this.lineInterval) {
      clearInterval(this.lineInterval);
    }

  },
  beforeDestroy() {
    window.onresize = null;
    if (this.lineInterval) {
      clearInterval(this.lineInterval); this.lineInterval
      this.lineInterval = null;
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    }
    this.getDeviceDetail();
  },
  watch: {
    detailid: function () {
      this.getDeviceDetail();
      this.onDateClick(7);
    },
  },
};
</script>