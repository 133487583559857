<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/line");
require("echarts/lib/chart/graph");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/visualMap");

export default {
    data() {
        return {};
    },
    props: {
        annualrainfall:{
            type:Number,
            default:1500,
        },
        data: {
            type: Array,
        },
    },
    methods: {
        initChart() {
            let annualRainfall = this.annualrainfall>0 ? this.annualrainfall:1500;
            let ymax = 0;
            let xmax = 0;
            var ylSensorListData = this.data;
            let links = [];
            let title = '';
            ylSensorListData.forEach((element,idx) => {
                if (element.tenminCount > ymax){
                    ymax = element.tenminCount
                }
                if (element.valueableCount > xmax){
                    xmax = element.valueableCount
                }  
                if(element.time){
                    title = element.time;
                }
                links.push({
                    source: idx,
                    target: idx + 1,
                    lineStyle: {
                        width: 2,
                        color: "#000"
                    },
                });
            });
            if (xmax < annualRainfall * 0.2){
                xmax = annualRainfall * 0.2
            }
            if (ymax < annualRainfall * 0.05){
                ymax = annualRainfall * 0.05
            }

            links.pop();
                

            var chartOpt = {
                cgqtext: "传感器编号",
                title:{
                    text: title,
                    left:'30%',
                    top:'60',
                    textStyle:{
                        fontSize:12,
                    }
                },
                grid: {
                    top: "20%",
                    left: "40",
                    right: "80",
                    bottom: "20",
                },
                visualMap: [
                    {
                        type: "piecewise",
                        show: false,
                        dimension: 0,
                        seriesIndex: 3,
                        pieces: [
                            {
                                gt: 0,
                                lt: xmax * 1.11,
                                color: "#8ffa8f",
                            },
                        ],
                    },
                    {
                        type: "piecewise",
                        show: false,
                        dimension: 0,
                        seriesIndex: 2,
                        pieces: [
                            {
                                gt: annualRainfall * 0.02,
                                lt: xmax * 1.11,
                                color: "#ffff71",
                            },
                        ],
                    },
                    {
                        type: "piecewise",
                        show: false,
                        dimension: 0,
                        seriesIndex: 1,
                        pieces: [
                            {
                                gt: annualRainfall * 0.03,
                                lt: xmax * 1.11,
                                color: "#ffb568",
                            },
                        ],
                    },
                    {
                        type: "piecewise",
                        show: false,
                        dimension: 0,
                        seriesIndex: 0,
                        pieces: [
                            {
                                gt: annualRainfall * 0.04,
                                lt: xmax * 1.11,
                                color: "#ff6969",
                            },
                        ],
                    },
                ],
                tooltip:{
                    formatter:function(params){
                        if(params.name==''){
                            return '小时雨强：'+params.value[1]+'<br/>有效雨量：'+params.value[0];
                        }
                        return '';
                    },
                },
                xAxis: {
                    type: "value",
                    name: "有效雨量",
                    nameTextStyle: {
                        color: "#555",
                        fontSize: 12,
                    },
                    axisLabel: {
                        //坐标轴刻度标签的相关设置
                        textStyle: {
                            color: "#555",
                            margin: 15,
                        },
                        show: true,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#555",
                        },
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: true,
                    },
                },
                yAxis: {
                    type: "value",
                    name: "小时雨强",
                    nameTextStyle: {
                        color: "#555",
                        fontSize: 12,
                    },
                    axisLabel: {
                        //坐标轴刻度标签的相关设置
                        textStyle: {
                            color: "#555",
                            margin: 15,
                        },
                        show: true,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#555",
                        },
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: true,
                    },
                },
                series: [
                    {
                        type: "line",
                        symbol: "none",
                        lineStyle: {
                            color: "black",
                            width: 1,
                        },
                        areaStyle: {
                            opacity: 1,
                        },
                        data: [
                            [annualRainfall * 0.04, ymax * 1.11],
                            [xmax * 1.11, ymax * 1.11],
                            [xmax * 1.11, 0],
                        ],
                    },
                    {
                        type: "line",
                        symbol: "none",
                        lineStyle: {
                            color: "black",
                            width: 1,
                        },
                        areaStyle: {
                            opacity: 1,
                        },
                        data: [
                            [annualRainfall * 0.03, ymax * 1.11],
                            [annualRainfall * 0.04, ymax * 1.11],
                            [annualRainfall * 0.04, annualRainfall * 0.04],
                            [annualRainfall * 0.2, annualRainfall * 0.012],
                            [xmax * 1.11, annualRainfall * 0.012],
                        ],
                    },

                    {
                        type: "line",
                        symbol: "none",
                        lineStyle: {
                            color: "black",
                            width: 1,
                        },
                        areaStyle: {
                            opacity: 1,
                        },
                        data: [
                            [annualRainfall * 0.02, ymax * 1.11],
                            [annualRainfall * 0.03, ymax * 1.11],
                            [annualRainfall * 0.03, annualRainfall * 0.03],
                            [annualRainfall * 0.15, annualRainfall * 0.009],
                            [xmax * 1.11, annualRainfall * 0.009],
                        ],
                    },
                    {
                        type: "line",
                        symbol: "none",
                        lineStyle: {
                            color: "black",
                            width: 1,
                        },
                        areaStyle: {
                            opacity: 1,
                        },
                        data: [
                            [0, ymax * 1.11],
                            [annualRainfall * 0.02, ymax * 1.11],
                            [annualRainfall * 0.02, annualRainfall * 0.02],
                            [annualRainfall * 0.1, annualRainfall * 0.006],
                            [xmax * 1.11, annualRainfall * 0.006],
                        ],
                    },
                    {
                        type: "line",
                        symbol: "none",
                        lineStyle:{
                            width: 0,
                        },
                        areaStyle: {
                            opacity: 0,
                        },
                        data: [
                            [0, ymax * 1.2],
                            [xmax*1.2, ymax * 1.2],
                        ],
                    },
                    {
                        type: "line",
                        symbol: "none",
                        lineStyle: {
                            color: "wihte",
                            width: 2,
                        },
                        areaStyle: {},
                        data: [
                            [0, 0],
                            [xmax * 1.11, 0],
                        ],
                    },
                    {
                        name: "传感器数据",
                        type: "graph",
                        coordinateSystem: "cartesian2d",
                        data: ylSensorListData.map((v, i) => {
                            return {
                                label: {
                                    show: v.time ? true : false,
                                    position: "top",
                                    color: "red",
                                    fontSize: 16,
                                    borderWidth: 1,
                                    borderColor: "#000",
                                    backgroundColor: "#fff",
                                    borderRadius: "5px",
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        color: "#000",
                                    },
                                },
                                value: [v.valueableCount, v.tenminCount],
                            };
                        }),
                        links: links,
                        symbolSize: (arr, it) => {
                            return it.dataIndex<ylSensorListData.length-1 ? [5,5] : [10,10];
                        },
                        edgeSymbol: ["none", "arrow"],
                        edgeSymbolSize: 5,
                        legendHoverLink: true,
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: "rgba(0,0,0,0.6)",
                            color: (it) => {
                                return it.dataIndex<ylSensorListData.length-1 ? '#000' : '#f00';
                            },
                        },
                        label: {
                            color: "#fff",
                            position: "right",
                        },
                        zlevel:10,
                    },
                ],
            };
            console.log(chartOpt);
            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(chartOpt);
        },
    },
    mounted() {
        setTimeout(() => {
            this.initChart();
        }, 500);
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.initChart();
            },
        },
        annualrainfall:{
            handler(){
                this.initChart();
            }
        },
    },
};
</script>